import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={12}
    s3Path={`portfolio/new-born/8/`}
  />
)

export default Showcase;
